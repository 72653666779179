import stationReducer from './stationReducer';
import { postData } from '../../lib/utils/httputils';

class CurrentStation {
  static currentStation = {};
  static async loadStation() {
    CurrentStation.currentStation = {};
  }
  static async saveStation(path, data) {
    if (!path) {
      return;
    }
    await postData(path, data, 'application/json; charset=UTF-8');
  }
}

export default CurrentStation;

export { stationReducer };
export * from './stationActions';
