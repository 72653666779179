import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-daterange-picker';
import moment from 'moment';
import 'react-daterange-picker/src/css/react-calendar.scss';
import I18n from '../../models/i18n';
import 'moment/locale/it';

class DatePicker extends PureComponent {
  static propTypes = {
    onDateRangeChanged: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    showCharts: PropTypes.bool
  };

  state = {
    value: null,
    states: null,
    range: null
  };

  render() {
    const { range, showCharts } = this.props;
    let dates = null;
    let stateDefinitions = {
      available: {
        color: null,
        label: 'Available'
      },

      unavailable: {
        selectable: false,
        color: '#78818b',
        label: I18n.translate('calendar', 'unavailable')
      }
    };
    let dateRanges = [
      {
        state: 'unavailable',
        range: moment.range(moment(new Date()), moment().add(5, 'weeks'))
      }
    ];

    if (range && showCharts) {
      dates = range.split('|');
      dateRanges = [
        {
          state: 'unavailable',
          range: moment.range(moment(new Date()), moment().add(5, 'weeks'))
        },
        {
          state: 'enquire',
          range: moment.range(moment(dates[0], 'YYYYMMDD'), moment(dates[1], 'YYYYMMDD'))
        }
      ];
      stateDefinitions = {
        available: {
          color: null,
          label: 'Available'
        },

        enquire: {
          color: '#ffd200',
          label: I18n.translate('calendar', 'enquire')
        },

        unavailable: {
          selectable: false,
          color: '#78818b',
          label: I18n.translate('calendar', 'unavailable')
        }
      };
    }

    return (
      <DateRangePicker
        firstOfWeek={1}
        numberOfCalendars={1}
        locale={this.props.lang.lang}
        selectionType="range"
        maximumDate={this.getMaximumDate()}
        stateDefinitions={stateDefinitions}
        singleDateRange={true}
        dateStates={dateRanges}
        defaultState="available"
        selectedLabel={I18n.translate('calendar', 'selected')}
        showLegend={true}
        value={this.state.value}
        onSelect={this.handleSelect}
      />
    );
  }

  handleSelect = (range, states) => {
    // range is a moment-range object
    this.setState(
      {
        value: range,
        states: states
      },
      () => {
        const rangeStart = moment(range.start).format('YYYYMMDD');
        const rangeEnd = moment(range.end).format('YYYYMMDD');
        // serve una stringa da mettere nell'url
        this.props.onDateRangeChanged(rangeStart + '|' + rangeEnd);
      }
    );
  };
  getMaximumDate = () => {
    return moment(new Date())
      .subtract(1, 'days')
      .toDate();
  };
}

export default DatePicker;
