import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup, LayerGroup, Circle } from 'react-leaflet';
import PropTypes from 'prop-types';
import { getStationLink } from './StationsUtils';
import { getMSPColor } from '../../lib/utils/mspUtils';

export default class StationsMap extends Component {
  static propTypes = {
    stations: PropTypes.array.isRequired,
    onStationClick: PropTypes.func.isRequired,
    embed: PropTypes.bool,
    height: PropTypes.string
  };

  state = {
    stations: [],
    lat: 45.463,
    lng: 9.19
  };

  render() {
    const { lat, lng, stations } = this.state;
    if (stations.length === 0) {
      return <div />;
    }
    const { embed, onStationClick, height } = this.props;
    const position = [lat, lng];
    const markers = stations.map(station => {
      return [station.latitude, station.longitude];
    });
    let _height = undefined;
    if (embed) {
      _height = height ? (isNaN(height) ? height : Number(height)) : 560;
    }
    return (
      <Map center={position} bounds={markers} style={{ height: _height }}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {stations.map((station, i) => {
          let circleOptions;
          if (station.last24 && station.last24.msp !== null) {
            const mspColor = getMSPColor(station.last24.msp);
            circleOptions = { fill: true, fillColor: mspColor, color: mspColor };
          }
          return (
            <div key={i}>
              <LayerGroup>
                <Marker position={[station.latitude, station.longitude]}>
                  <Popup minWidth={200}>
                    {getStationLink(station, embed, onStationClick, true)}
                    {station.last24 && (
                      <dl className="mt-2 row">
                        <dt className="col-6">PM 2.5</dt>
                        <dd className="col-6">{station.last24.PM2_5}</dd>
                        <dt className="col-6">Temp</dt>
                        <dd className="col-6">{station.last24.temp} C</dd>
                        {station.last24.msp !== null && (
                          <>
                            <dt className="col-6">MSP#</dt>
                            <dd className="col-6">
                              <svg width="12" height="12">
                                <rect
                                  width="12"
                                  height="12"
                                  style={{ fill: circleOptions.color, strokeWidth: 3, stroke: circleOptions.color }}
                                />
                              </svg>
                            </dd>
                          </>
                        )}
                      </dl>
                    )}
                  </Popup>
                </Marker>
                {circleOptions && (
                  <Circle center={[station.latitude, station.longitude]} {...circleOptions} radius={100} />
                )}
              </LayerGroup>
            </div>
          );
        })}
      </Map>
    );
  }

  componentDidMount() {
    this.filterStations();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // TODO meglio verificare se effettivamente sono diverse le stations...
    if (
      prevProps.stations.length !== this.props.stations.length ||
      prevProps.stations[0].id !== this.props.stations[0].id
    ) {
      this.filterStations();
    }
  }

  filterStations = () => {
    const { stations } = this.props;
    const _stations = [];
    stations.forEach(station => {
      if (station.show_on_map) {
        _stations.push(station);
      }
    });
    this.setState({ stations: _stations }, this.setCenterPosition);
  };

  setCenterPosition = () => {
    const { stations } = this.state;
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLong = Infinity;
    let maxLong = -Infinity;
    stations.forEach(station => {
      const longitude = Number(station.longitude);
      const latitude = Number(station.latitude);
      if (longitude < minLong) {
        minLong = longitude;
      }
      if (longitude > maxLong) {
        maxLong = longitude;
      }
      if (latitude < minLat) {
        minLat = latitude;
      }
      if (latitude > maxLat) {
        maxLat = latitude;
      }
    });
    const lat = minLat + (maxLat - minLat) / 2;
    const lng = minLong + (maxLong - minLong) / 2;
    this.setState({ lat, lng });
  };
}
