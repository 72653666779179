import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { i18nReducer } from '../models/i18n/';
import { dataReducer } from '../models/data/';
import { stationReducer } from '../models/station';

export default history =>
  combineReducers({
    router: connectRouter(history),
    lang: i18nReducer,
    data: dataReducer,
    station: stationReducer
  });
