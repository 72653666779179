import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ChartModal(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header style={{ backgroundColor: '#2bd137' }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">{props.children}</div>
      </Modal.Body>
    </Modal>
  );
}

export default ChartModal;
