import React from 'react';
import {
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  DiscreteColorLegend
} from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';
import ChartModalContainer from '../CommonComponents/PMChartInfoModal';

class PmChart extends BaseChart {
  static propTypes = {
    dataPm1: PropTypes.array.isRequired,
    dataPm2_5: PropTypes.array.isRequired,
    dataPm10: PropTypes.array.isRequired,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    ticksX: PropTypes.number,
    sensors: PropTypes.object
  };

  state = {
    valuePm1: false,
    valuePm2_5: false,
    valuePm10: false,
    cutOffPmVeryGood: false,
    cutOffPmGood: false,
    cutOffPmFair: false,
    cutOffPmPoor: false,
    cutOffPmVeryPoor: false,
    subtitle: false,
    xStart: false,
    xEnd: false,
    yMaxValue: false
  };

  render() {
    const { width, dataPm1, dataPm2_5, dataPm10 } = this.props;
    const {
      cutOffPmVeryGood,
      cutOffPmGood,
      cutOffPmFair,
      cutOffPmPoor,
      cutOffPmVeryPoor,
      subtitle,
      xStart,
      xEnd,
      yMaxValue
    } = this.state;
    if (!cutOffPmVeryGood) return null;
    const { h, w } = this.getHeight(width);

    return (
      <ChartWrapper
        title={I18n.translate('data', 'tabellaPM_title')}
        subtitle={subtitle}
        modalContent={<ChartModalContainer />}
      >
        <div className="legend-wrapper">
          <DiscreteColorLegend
            items={[
              {
                title: 'PM1',
                color: '#D833DB'
              },
              {
                title: 'PM2.5',
                color: '#27AADB'
              },
              {
                title: 'PM10',
                color: '#611CC6'
              }
            ]}
          />
        </div>
        <XYPlot
          xType="time"
          width={w}
          height={h}
          onMouseLeave={() => this.setState({ valuePm1: false, valuePm2_5: false, valuePm10: false })}
          onTouchEnd={() => this.setState({ valuePm1: false, valuePm2_5: false, valuePm10: false })}
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
          xDomain={[xStart, xEnd]}
          yDomain={[0, yMaxValue]}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={this.props.ticksX} />
          <YAxis />

          {dataPm1.map((serie, i) => {
            return (
              <LineSeries
                key={i}
                style={{ zIndex: '998' }}
                data={serie}
                onNearestX={(valueSerie, event) => {
                  this.queueNearestX('valuePm1', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                }}
                color="#D833DB"
                curve={'curveMonotoneX'}
              />
            );
          })}
          {dataPm2_5.map((serie, i) => {
            return (
              <LineSeries
                key={i}
                style={{ zIndex: '998' }}
                data={serie}
                onNearestX={(valueSerie, event) => {
                  this.queueNearestX('valuePm2_5', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                }}
                color="#27AADB"
                curve={'curveMonotoneX'}
              />
            );
          })}
          {dataPm10.map((serie, i) => {
            return (
              <LineSeries
                key={i}
                style={{ zIndex: '998' }}
                data={serie}
                onNearestX={(valueSerie, event) => {
                  this.queueNearestX('valuePm10', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                }}
                color="#611CC6"
                curve={'curveMonotoneX'}
              />
            );
          })}

          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmGood}
            stroke="#1DE326"
            strokeStyle="dashed"
            strokeWidth={3}
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmFair}
            stroke="#FFEC33"
            strokeStyle="dashed"
            strokeWidth={3}
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmPoor}
            stroke="#FF9C33"
            strokeStyle="dashed"
            strokeWidth={3}
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmVeryPoor}
            stroke="#FF0000"
            strokeStyle="dashed"
            strokeWidth={3}
          />

          {(this.state.valuePm1 || this.state.valuePm2_5 || this.state.valuePm10) && (
            <Hint value={this.state.valuePm10 || this.state.valuePm2_5 || this.state.valuePm1}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                {I18n.translate('data', 'date_time')}: <strong>{this.props.getDateTime(this.state.valuePm1.x)}</strong>
                <br />
                PM1: <strong>{this.state.valuePm1 ? this.state.valuePm1.y : '-'}</strong>
                <br />
                PM2.5: <strong>{this.state.valuePm2_5 ? this.state.valuePm2_5.y : '-'}</strong>
                <br />
                PM10: <strong>{this.state.valuePm10 ? this.state.valuePm10.y : '-'}</strong>
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }

  componentDidMount() {
    this.computeState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dataPm1 } = this.props;
    if (
      dataPm1[0][0].x !== this.state.xStart ||
      dataPm1[dataPm1.length - 1][dataPm1[dataPm1.length - 1].length - 1].x !== this.state.xEnd
    ) {
      this.computeState();
    }
  }

  computeState() {
    const { dataPm10, dataPm1, sensors } = this.props;
    let yMaxValue = 0;
    dataPm10.forEach(serie => {
      serie.forEach(data => {
        if (data.y > yMaxValue) {
          yMaxValue = data.y;
        }
      });
    });
    yMaxValue += yMaxValue * 0.1;
    const xStart = dataPm1[0][0].x;
    const xEnd = dataPm1[dataPm1.length - 1][dataPm1[dataPm1.length - 1].length - 1].x;
    const cutOffPmVeryGood = [
      { x: xStart, y: 0.2 },
      { x: xEnd, y: 0.2 }
    ];
    const cutOffPmGood = [
      { x: xStart, y: 10 },
      { x: xEnd, y: 10 }
    ];
    const cutOffPmFair = [
      { x: xStart, y: 20 },
      { x: xEnd, y: 20 }
    ];
    const cutOffPmPoor = [
      { x: xStart, y: 25 },
      { x: xEnd, y: 25 }
    ];
    const cutOffPmVeryPoor = [
      { x: xStart, y: 50 },
      { x: xEnd, y: 50 }
    ];
    let subtitle = '';
    if (sensors.tolerance) {
      const values = [
        { id: 'PM1', desc: 'PM1' },
        { id: 'PM2_5', desc: 'PM2.5' },
        { id: 'PM10', desc: 'PM10' }
      ];
      subtitle =
        '(' +
        values
          .map(k => {
            return k.desc + ': ' + sensors.tolerance[k.id];
          })
          .join(', ') +
        ')';
    }
    this.setState({
      cutOffPmVeryGood,
      cutOffPmGood,
      cutOffPmFair,
      cutOffPmPoor,
      cutOffPmVeryPoor,
      subtitle,
      xStart,
      xEnd,
      yMaxValue
    });
  }
}

export default PmChart;
