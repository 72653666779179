import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TitleLine extends PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,
    marginLeft: PropTypes.string
  };

  render() {
    const styles = {};
    if (this.props.marginLeft) {
      styles.marginLeft = this.props.marginLeft;
    }
    return <hr style={styles} className={'highlight-title ' + this.props.color} />;
  }
}

export default TitleLine;
