import React from 'react';
import ChartModal from './ChartModal';

function ChartWrapper(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="card shadow">
      <div className="card-header graph-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10">
              <div className="card-header-text">
                <h3 className="chart-title">{props.title}</h3>
                <h6 className="chart-subtitle">{props.subtitle}</h6>
              </div>
            </div>
            <div className="col-1">
              <button
                className={props.modalContent ? 'info-button' : 'info-button-inactive'}
                onClick={() => setModalShow(true)}
              >
                i
              </button>
              <ChartModal show={modalShow} onHide={() => setModalShow(false)} title={props.title}>
                {props.modalContent}
              </ChartModal>
            </div>
          </div>
        </div>
      </div>
      <div className="graph-wrapper">{props.children}</div>
    </div>
  );
}

export default ChartWrapper;
