import React, { PureComponent } from 'react';
import { setCurrentStation } from '../../models/station';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StationsMap from './StationsMap';
import qs from 'qs';
import Loading from '../CommonComponents/Loading';

class StationsMapPage extends PureComponent {
  state = {
    stations: {},
    currentStation: {}
  };

  render() {
    const { stations } = this.state;
    if (!stations.rows) {
      return <Loading/>;
    }
    if (stations.rows.length === 0) {
      return <div className="pt-5 mt-5 text-center">No stations found.</div>;
    }
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const height = queries.height || '';

    return (
      <div className="top-page">
        <StationsMap
          stations={stations.rows}
          height={height}
          onStationClick={this.saveCurrentStation}
          embed={this.isEmbed}
        />
      </div>
    );
  }

  componentDidMount() {
    this.fetchStations();
    this.isEmbed = this.props.location.pathname.indexOf('/embed') > 0;
  }

  fetchStations() {
    const { search } = this.props.location;
    fetch(`/api/v1/stations${search}`)
      .then(res => {
        return res.json();
      })
      .then(stations => this.setState({ stations }));
  }

  saveCurrentStation = (e, station) => {
    this.props.dispatch(setCurrentStation(station));
  };
}

function mapStateToProps(state) {
  const { station } = state;

  return {
    station
  };
}

export default withRouter(connect(mapStateToProps)(StationsMapPage));
