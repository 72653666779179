import { RETRIEVE_DATA, SET_DATA, SET_DATA_ERROR } from './dataActions';

const initialState = {
  loaded: false,
  loading: false,
  data: null,
  error: null
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return Object.assign({}, state, { loading: false, loaded: true, data: action.data, error: null });
    case SET_DATA_ERROR:
      return Object.assign({}, state, { loaded: true, error: action.data, data: null });
    case RETRIEVE_DATA:
      return Object.assign({}, state, { loading: true, error: null });
    default:
      return state;
  }
}
