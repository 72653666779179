import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import store, { history } from "../../store";
import { ConnectedRouter } from "connected-react-router";
import { BrowserRouter as Router } from "react-router-dom";
import WrapApp from "./WrapApp";
import ErrorBoundary from "./ErrorBoundary";
import "./app.scss";
import { getTheme } from "../../lib/utils/windowUtils";

class BaseApp extends PureComponent {
  state = {
    config: null
  };

  render() {
    const { config } = this.state;
    if (!config) {
      return <></>;
    }
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router>
            <div
              className={`app-wrapper ${
                config.theme ? `theme-${config.theme}` : ""
              }`}
            >
              <ErrorBoundary>
                <WrapApp config={config} />
              </ErrorBoundary>
            </div>
          </Router>
        </ConnectedRouter>
      </Provider>
    );
  }

  setupTheme = theme => {
    if (!theme) {
      theme = getTheme();
    }
    if (theme) {
      this.theme = theme;
      const themeEl = document.createElement("link");
      themeEl.setAttribute("rel", "stylesheet");
      themeEl.setAttribute("href", `/stylesheets/${theme}.css`);
      document.head.appendChild(themeEl);
    }
  };

  componentDidMount() {
    fetch(`/api/v1/config`)
      .then(res => {
        return res.json();
      })
      .then(config => {
        this.setupTheme(config.theme);
        this.setState({ config });
      });
  }
}

export default BaseApp;
