import React from 'react';
import {
  DiscreteColorLegend,
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';

class HumChart extends BaseChart {
  static propTypes = {
    dataHum: PropTypes.array.isRequired,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    ticksX: PropTypes.number
  };

  state = {
    valueHum: false,
    valueHumMax: false,
    valueHumMin: false
  };

  render() {
    const { valueHum, valueHumMax, valueHumMin } = this.state;
    const { width } = this.props;
    const { h, w } = this.getHeight(width);

    return (
      <ChartWrapper
        title={I18n.translate('data', 'tabellaHum_title')}
        subtitle={I18n.translate('data', 'tabellaHum_subtitle')}
      >
        <div className="legend-wrapper">
          <DiscreteColorLegend
            items={[
              {
                title: '% Hum',
                color: '#256d1b'
              },
              {
                title: 'Max % Hum',
                color: '#ee908b'
              },
              {
                title: 'Min % Hum',
                color: '#99a8ec'
              }
            ]}
          />{' '}
        </div>
        <XYPlot
          xType="time"
          width={w}
          height={h}
          yDomain={[0, 100]}
          onMouseLeave={() => this.setState({ valueHum: false, valueHumMax: false, valueHumMin: false })}
          onTouchEnd={() => this.setState({ valueHum: false, valueHumMax: false, valueHumMin: false })}
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={this.props.ticksX} />
          <YAxis />

          {this.props.dataHum.map((serie, i) => {
            return (
              <LineSeries
                key={i}
                data={serie}
                onNearestX={(valueSerie, event) => {
                  this.queueNearestX('valueHum', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                }}
                color="#256d1b"
                curve={'curveMonotoneX'}
              />
            );
          })}
          {this.props.dataHumMax &&
            this.props.dataHumMax.length > 0 &&
            this.props.dataHumMax.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valueHumMax', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  strokeWidth="1"
                  color="#ee908b"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {this.props.dataHumMin &&
            this.props.dataHumMin.length > 0 &&
            this.props.dataHumMin.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valueHumMin', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  strokeWidth="1"
                  color="#99a8ec"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {(valueHum || valueHumMax || valueHumMin) && (
            <Hint value={valueHum || valueHumMax || valueHumMin}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                {I18n.translate('data', 'date_time')} <strong>{this.props.getDateTime(valueHum.x)}</strong>
                <br />
                {this.props.dataHumMax !== null && I18n.translate('data', 'hum_max')}{' '}
                <strong>{valueHumMax ? valueHumMax.y + '%' : '-'}</strong>
                <br />
                {I18n.translate('data', 'humidity')} <strong>{valueHum ? valueHum.y + '%' : '-'}</strong>
                <br />
                {this.props.dataHumMin !== null && I18n.translate('data', 'hum_min')}{' '}
                <strong>{valueHumMin ? valueHumMin.y + '%' : '-'}</strong>
                <br />
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }
}

export default HumChart;
