import { PureComponent } from 'react';

class BaseChart extends PureComponent {
  getHeight(width) {
    const width43 = (width * 9.3) / 10;
    const height43 = (width43 * 3) / 4;
    const height =
      height43 > (window.innerHeight * 0.9) / 2 && width43 / (window.innerHeight / 2 - 100) <= 3.5
        ? window.innerHeight / 2 - 100
        : height43 > window.innerHeight
        ? window.innerHeight * 0.8
        : height43;
    return { h: height, w: width43 };
  }

  queueNearestXTimeout = {};
  queuedNearestX = {};
  hintTimeout = {};
  queueNearestX = (valueName, valueSerie, xgap) => {
    if (this.queueNearestXTimeout[valueName]) {
      clearTimeout(this.queueNearestXTimeout[valueName]);
    }
    if (!this.queuedNearestX[valueName]) {
      this.queuedNearestX[valueName] = { valueSerie, xgap };
    } else {
      // console.log(xgap, this.queuedNearestX.xgap);
      if (xgap < this.queuedNearestX[valueName].xgap) {
        // console.log('replace');
        this.queuedNearestX[valueName] = { valueSerie, xgap };
      }
    }

    this.queueNearestXTimeout[valueName] = setTimeout(() => {
      this.setState({ [valueName]: this.queuedNearestX[valueName].valueSerie }, () => {
        delete this.queuedNearestX[valueName];
        if (this.hintTimeout[[valueName]]) {
          clearTimeout(this.hintTimeout[valueName]);
        }
        this.hintTimeout[valueName] = setTimeout(() => {
          this.setState({ [valueName]: false });
        }, 5000);
      });
    }, 5);
  };
}

export default BaseChart;
