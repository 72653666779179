import React from "react";
import Icon from "../Icon";

const IconSection = () => {
  return (
    <div className="py-5 bg-grey">
      <div className="container">
        <div className="row">
          <div className="text-center col-12 col-md-4">
            <Icon
              icon={{
                iconClass: "iconscience",
                iconUrl: "/assets/icon-science.png",
                iconAlt: "icona scienza",
                iconText: "science"
              }}
              targetSection=""
            />
          </div>
          <div className="text-center col-12 col-md-4">
            <Icon
              icon={{
                iconClass: "icongraphs",
                iconUrl: "/assets/icon-graphs.png",
                iconAlt: "icona dati rilevati",
                iconText: "graphs"
              }}
              targetSection="/stations"
            />
          </div>
          <div className="text-center col-12 col-md-4">
            <Icon
              icon={{
                iconClass: "iconcode",
                iconUrl: "/assets/icon-code.png",
                iconAlt: "icona open code",
                iconText: "code"
              }}
              targetSection=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconSection;
