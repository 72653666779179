import React from 'react';
import {
  DiscreteColorLegend,
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleLine from '../CommonComponents/TitleLine';

class TempChart extends BaseChart {
  static propTypes = {
    dataTemp: PropTypes.array.isRequired,
    dataTempMin: PropTypes.array,
    dataTempMax: PropTypes.array,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    ticksX: PropTypes.number
  };

  state = {
    valueTemp: false,
    valueTempMin: false,
    valueTempMax: false
  };

  render() {
    const { valueTemp, valueTempMax, valueTempMin } = this.state;
    const { dataTemp, ticksX, getDateTime, dataTempMin, dataTempMax } = this.props;
    const { width } = this.props;
    const { h, w } = this.getHeight(width);

    return (
      <ChartWrapper
        title={I18n.translate('data', 'tabellaTemp_title')}
        subtitle={I18n.translate('data', 'tabellaTemp_subtitle')}
        modalContent={
          <Container>
            <h6>{I18n.translate('modal', 'tabellaTemp_desc')}</h6>
            <TitleLine color="green" />
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#FF0000' }}>{I18n.translate('modal', 'dangerous')}</p>
              </Col>
              <Col xs={6}>
                <p>&gt; 45</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#FF9C33' }}>{I18n.translate('modal', 'greatdiscomfort')}</p>
              </Col>
              <Col xs={6}>
                <p>40 to 45</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#FFC300' }}>{I18n.translate('modal', 'somediscomfort')}</p>
              </Col>
              <Col xs={6}>
                <p>30 to 39</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#1DE326' }}>{I18n.translate('modal', 'nodiscomfort')}</p>
              </Col>
              <Col xs={6}>
                <p>20 to 29</p>
              </Col>
            </Row>
          </Container>
        }
      >
        <div className="legend-wrapper">
          <DiscreteColorLegend
            items={[
              {
                title: 'Temp °C',
                color: '#244F26'
              },
              {
                title: 'Max Temp °C',
                color: '#ee908b'
              },
              {
                title: 'Min Temp °C',
                color: '#99a8ec'
              }
            ]}
          />{' '}
        </div>
        <XYPlot
          xType="time"
          width={w}
          height={h}
          yDomain={[-15, 45]}
          onMouseLeave={() =>
            this.setState({ valueTemp: false, valueHumidex: false, valueTempMin: false, valueTempMax: false })
          }
          onTouchEnd={() =>
            this.setState({ valueTemp: false, valueHumidex: false, valueTempMin: false, valueTempMax: false })
          }
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={ticksX} />
          <YAxis />
          {dataTemp.map((serie, i) => {
            return (
              <LineSeries
                key={i}
                data={serie}
                onNearestX={(valueSerie, event) => {
                  this.queueNearestX('valueTemp', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                }}
                color="#244F26"
                curve={'curveMonotoneX'}
              />
            );
          })}
          {dataTempMin &&
            dataTempMin.length > 0 &&
            dataTempMin.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valueTempMin', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  strokeWidth="1"
                  color="#99a8ec"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {dataTempMax &&
            dataTempMax.length > 0 &&
            dataTempMax.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valueTempMax', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  strokeWidth="1"
                  color="#ee908b"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {(valueTemp || valueTempMax || valueTempMin) && (
            <Hint value={valueTemp}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                {I18n.translate('data', 'date_time')} <strong>{getDateTime(valueTemp.x)}</strong>
                <br />
                {I18n.translate('data', 'temperatura')} <strong>{valueTemp ? valueTemp.y + '° C' : '-'}</strong>
                <br />
                {I18n.translate('data', 'temp_max')} <strong>{valueTempMax ? valueTempMax.y + '° C' : '-'}</strong>
                <br />
                {I18n.translate('data', 'temp_min')} <strong>{valueTempMin ? valueTempMin.y + '° C' : '-'}</strong>
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }
}

export default TempChart;
