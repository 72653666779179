import HTTPError from './httperror';

export function getData(path) {
  return sendData(path, undefined, undefined, 'GET');
}

export function postData(path, data, contentType = 'application/json; charset=UTF-8') {
  return sendData(path, data, contentType, 'POST');
}

export function putData(path, data, contentType = 'application/json; charset=UTF-8') {
  return sendData(path, data, contentType, 'PUT');
}

export function deleteData(path, data, contentType = 'application/json; charset=UTF-8') {
  return sendData(path, data, contentType, 'DELETE');
}

async function _parseJsonBody(contentType, response) {
  if (!contentType) return false;
  if (contentType.includes('application/json')) {
    return response.json();
  }
  return false;
}

async function sendData(path, data, contentType, method) {
  const headers = {};
  let body;
  if (data) {
    headers['Content-Type'] = contentType;
    if (typeof data !== 'string') {
      body = JSON.stringify(data);
    } else {
      body = data;
    }
  }
  let response;
  try {
    response = await fetch(path, {
      credentials: 'include',
      method,
      headers,
      body
    });
  } catch (ex) {
    console.error('WTF', ex);
    throw ex;
  }
  const retContentType = response.headers.get('content-type');
  if (response.status === 204) {
    return true;
  }
  if (response.status >= 500) {
    // Server's fault.. just raise the error
    let json;
    try {
      json = await _parseJsonBody(retContentType, response);
    } catch (e) {
      throw new HTTPError(response.statusText, response.status, response);
    }
    throw new HTTPError(json.reason || json.message, json.status || json.error, response);
  }
  if (response.status >= 400) {
    // Our fault..
    // Session expired?
    if (response.status === 401) {
      // Force logout from here?
    }

    let json;
    try {
      json = await _parseJsonBody(retContentType, response);
    } catch (e) {
      throw new HTTPError(response.statusText, response.status, response);
    }
    throw new HTTPError(json.reason || json.message, json.status || json.error, response);
  }
  if (!retContentType) {
    return true;
  }
  const json = await _parseJsonBody(retContentType, response);
  if (json === false) {
    return response.text();
  }
  return json;
}

export function toParams(obj, includeInitial) {
  const query = Object.keys(obj)
    .map(function(key) {
      return key + '=' + encodeURIComponent(obj[key]);
    })
    .join('&');
  if (includeInitial && query !== '') {
    return '?' + query;
  }
  return query;
}
