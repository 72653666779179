import { Link } from 'react-router-dom';
import React from 'react';
import { getTheme } from '../../lib/utils/windowUtils';

export const getStationLink = function(station, embed, onClick, showCode = false) {
  const Content = function() {
    return (
      <>
        {showCode && (
          <>
            {station.code}
            <br />
          </>
        )}
        {station.name}
        <br />
        {station.indoor ? 'INDOOR' : 'OUTDOOR'}
      </>
    );
  };
  if (embed) {
    const theme = getTheme();
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`/stations/embed/${station.code}${theme ? '?theme=' + theme : ''}`}
      >
        <Content />
      </a>
    );
  }
  return (
    <Link to={`/stations/${station.code}`} onClick={onClick}>
      <Content />
    </Link>
  );
};
