import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter, Switch } from 'react-router-dom';
import { doGetTranslations } from '../../models/i18n';
import Navbar from '../Navbar';
import Data from '../Data';
import Home from '../Home';
import StationsPage from '../Stations/StationsPage';
import StatsPage from '../Stats';
import Footer from '../Footer';
import StationsMapPage from '../Stations/StationsMapPage';
import StationsListPage from '../Stations/StationsListPage';
import { getStorageKey } from '../../lib/utils/storageUtils';
import Last24h from '../Stats/Last24h';
import LastMSP from '../Stats/LastMSP';
import Loading from '../CommonComponents/Loading';

class WrapApp extends PureComponent {
  static propTypes = {
    config: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    if (!this.props.lang.loaded) {
      return <Loading/>;
    }
    return (
      <Switch>
        <Route path="/stats/embed/last24h" exact component={Last24h} />
        <Route path="/stats/embed/last24h/:type" exact component={Last24h} />
        <Route path="/stats/embed/msp" component={LastMSP} />
        <Route path="/stations/embed" exact component={StationsPage} />
        <Route path="/stations/embed/list" exact component={StationsListPage} />
        <Route path="/stations/embed/map" exact component={StationsMapPage} />
        <Route path="/stations/embed/:station" exact component={Data} />
        <Route path="/stats/embed" component={StatsPage} />
        <Route path="/">
          <div className="app-inner">
            <Navbar lang={this.props.lang} location={this.props.location} dispatch={this.props.dispatch} />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/stations/:station" exact component={Data} />
              <Route path="/stations" exact component={StationsPage} />
              <Route path="/stats" component={StatsPage} />
            </Switch>
            {/* <Footer config={this.props.config} /> */}
          </div>
        </Route>
      </Switch>
    );
  }

  componentDidMount() {
    const lang = getStorageKey('lang', 'it');
    this.props.dispatch(doGetTranslations(lang));
  }
}

function mapStateToProps(state) {
  const { lang } = state;

  return {
    lang
  };
}

export default withRouter(connect(mapStateToProps)(WrapApp));
