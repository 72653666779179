import React, { PureComponent } from 'react';
import I18n from '../../models/i18n';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { getData } from '../../lib/utils/httputils';

class LastMSP extends PureComponent {
  state = {
    error: null,
    data: undefined
  };
  render() {
    const { data, error } = this.state;
    let mspGauge;
    if (!error && data) {
      const mspVal = Number.parseFloat(data.data[0].msp);
      mspGauge = mspVal === 1 ? 'green' : mspVal === 2 ? 'yellow' : mspVal === 3 ? 'red' : 'black';
    }
    const { desc, raw } = this.getGeoData();
    if (raw === '1') {
      return <img className="img-fluid" alt={mspGauge} src={`/assets/gauge/gauge-${mspGauge}.png`} />;
    }
    return (
      <div className="container mt-4">
        <div className="shadow-lg card mb-4">
          <div className="card-header graph-header">
            <div className="row">
              <div className="col-10">
                <div className="card-header-text">
                  <h3 className="chart-title py-2">{I18n.translate('stats', 'last_msp')}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            {error && <div className="alert alert-danger">{error}</div>}
            {!error && data && (
              <>
                <div className="row mb-3">
                  <div className="col-12 text-center">
                    <img className="img-fluid" alt={mspGauge} src={`/assets/gauge/gauge-${mspGauge}.png`} />
                  </div>
                </div>
                <em>{desc || ''}</em>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadData().catch(console.error);
  }

  loadData = async () => {
    const { lat, lng, range, all, type } = this.getGeoData();
    if (lat === undefined || lng === undefined || range === undefined || isNaN(lat) || isNaN(lng) || isNaN(range)) {
      this.setState({ error: 'Missing required parameters' });
      return;
    }
    try {
      const data = await getData(
        `/api/v1/stats/msp?lat=${lat}&lng=${lng}&range=${range}${all ? '&all=' + all : ''}${
          type ? '&type=' + type : ''
        }`
      );
      if (data.data.length < 1) {
        this.setState({ error: 'No data' });
      } else {
        this.setState({ data });
      }
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  getGeoData = () => {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { lat, lng, range, desc, all, type, raw } = queries;
    return { lat, lng, range, desc, all, type, raw };
  };
}

export default withRouter(LastMSP);
