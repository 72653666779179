import React from 'react';
import { Hint, MarkSeries, VerticalGridLines, XAxis, XYPlot } from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';
import { getMSPColor } from '../../lib/utils/mspUtils';

class MSPChart extends BaseChart {
  static propTypes = {
    dataSerie: PropTypes.array.isRequired,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    labelY: PropTypes.string,
    ticksX: PropTypes.number,
    sensors: PropTypes.object,
    title: PropTypes.string,
    code: PropTypes.string
  };

  state = {
    valueSerie: false
  };

  render() {
    const { width, dataSerie, title, code } = this.props;
    const xStart = dataSerie.length > 0 ? dataSerie[0].x : 0;
    const xEnd = dataSerie.length > 0 ? dataSerie[dataSerie.length - 1].x : 0;
    const { h, w } = this.getHeight(width);

    return (
      <ChartWrapper title={I18n.translate('data', `tabella_${code}_title`)}>
        <XYPlot
          xType="time"
          width={w}
          height={h}
          onMouseLeave={() => this.setState({ valueSerie: false })}
          onTouchEnd={() => this.setState({ valueSerie: false })}
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
          xDomain={[xStart, xEnd]}
          yDomain={[1, 4]}
        >
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={this.props.ticksX} />

          {dataSerie.map((data, i) => {
            const color = getMSPColor(data.y);
            return (
              <MarkSeries
                key={i}
                className="mark-series-example"
                strokeWidth={4}
                opacity="1"
                sizeRange={[1, 4]}
                data={[data]}
                style={{
                  zIndex: '998'
                }}
                fill={color}
                stroke={color}
                onNearestX={valueSerie => this.setState({ valueSerie })}
              />
            );
          })}

          {this.state.valueSerie && (
            <Hint value={this.state.valueSerie}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                {I18n.translate('data', 'date_time')}:{' '}
                <strong>{this.props.getDateTime(this.state.valueSerie.x)}</strong>
                <br />
                {title}: <strong>{this.state.valueSerie ? this.state.valueSerie.y : '-'}</strong>
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }
}

export default MSPChart;
