export const SET_CURRENTSTATION = 'SET_CURRENTSTATION';
export const SET_CURRENTSTATION_ERROR = 'SET_CURRENTSTATION_ERROR';

export function setCurrentStation(station) {
  return {
    type: SET_CURRENTSTATION,
    currentStation: station
  };
}

export function setCurrentStationError(error) {
  return {
    type: SET_CURRENTSTATION_ERROR,
    error
  };
}
