import React from 'react';
import { Hint, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';

class PressChart extends BaseChart {
  static propTypes = {
    dataPres: PropTypes.array.isRequired,
    dataPresMax: PropTypes.array,
    dataPresMin: PropTypes.array,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    ticksX: PropTypes.number
  };

  state = {
    value: false
  };

  render() {
    const { value, valuePresMax, valuePresMin } = this.state;
    const { width } = this.props;
    const { h, w } = this.getHeight(width);

    return (
      <ChartWrapper
        title={I18n.translate('data', 'tabellaPress_title')}
        subtitle={I18n.translate('data', 'tabellaPress_subtitle')}
      >
        <XYPlot
          xType="time"
          width={w}
          height={h}
          onMouseLeave={() => this.setState({ value: false })}
          onTouchEnd={() => this.setState({ value: false })}
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={this.props.ticksX} />
          <YAxis title="hPa" />
          {this.props.dataPres.map((serie, i) => {
            return (
              <LineSeries
                key={i}
                data={serie}
                onNearestX={(valueSerie, event) => {
                  this.queueNearestX('value', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                }}
                color="#256d1b"
                curve={'curveMonotoneX'}
              />
            );
          })}
          {this.props.dataPresMax &&
            this.props.dataPresMax.length > 0 &&
            this.props.dataPresMax.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valuePresMax', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  strokeWidth="1"
                  color="#ee908b"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {this.props.dataPresMin &&
            this.props.dataPresMin.length > 0 &&
            this.props.dataPresMin.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valuePresMin', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  strokeWidth="1"
                  color="#99a8ec"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {(value || valuePresMax || valuePresMin) && (
            <Hint value={value}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                Date time: <strong>{this.props.getDateTime(value.x)}</strong>
                <br />
                {valuePresMax && (
                  <>
                    Max: <strong>{valuePresMax.y} hPa</strong>
                    <br />
                  </>
                )}
                Press: <strong>{value.y} hPa</strong>
                {valuePresMin && (
                  <>
                    Min: <strong>{valuePresMin.y} hPa</strong>
                    <br />
                  </>
                )}
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }
}

export default PressChart;
