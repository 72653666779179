import React, { PureComponent } from 'react';
import { setCurrentStation } from '../../models/station';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import I18n from '../../models/i18n';
import { getStationLink } from './StationsUtils';
import { getMSPColor } from '../../lib/utils/mspUtils';
import Loading from '../CommonComponents/Loading';

class StationsListPage extends PureComponent {
  state = {
    stations: {},
    currentStation: {}
  };

  render() {
    const { stations } = this.state;
    if (!stations.rows) {
      return <Loading/>;
    }
    if (stations.rows.length === 0) {
      return <div className="pt-5 mt-5 text-center">No stations found.</div>;
    }
    return (
      <div className="container mt-4">
        <div className="mb-4 shadow-lg card">
          <div className="card-header graph-header">
            <div className="row">
              <div className="col-10">
                <div className="card-header-text">
                  <h3 className="py-2 chart-title">{I18n.translate('stations', 'title')}</h3>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">{I18n.translate('stations', 'code')}</th>
                <th scope="col">{I18n.translate('stations', 'name')}</th>
                <th scope="col">{I18n.translate('stations', 'station_type')}</th>
              </tr>
            </thead>
            <tbody>
              {stations.rows &&
                stations.rows.map((station, i) => {
                  return this.getStationRow(station, i);
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchStations();
    this.isEmbed = this.props.location.pathname.indexOf('/embed') > 0;
  }

  fetchStations() {
    const { search } = this.props.location;
    fetch(`/api/v1/stations${search}`)
      .then(res => {
        return res.json();
      })
      .then(stations => this.setState({ stations }));
  }

  saveCurrentStation = (e, station) => {
    this.props.dispatch(setCurrentStation(station));
  };

  getStationRow(station, i) {
    let mspColor;
    if (station.last24 && station.last24.msp) {
      mspColor = getMSPColor(station.last24.msp);
    }
    return (
      <tr key={i}>
        <td>
          {mspColor && (
            <span className="mr-2">
              <svg width="12" height="12">
                <rect width="12" height="12" style={{ fill: mspColor, strokeWidth: 3, stroke: mspColor }} />
              </svg>
            </span>
          )}
          {station.code}
        </td>
        <td>{getStationLink(station, this.isEmbed, e => this.saveCurrentStation(e, station))}</td>
        <td>{station.station_type}</td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  const { station } = state;

  return {
    station
  };
}

export default withRouter(connect(mapStateToProps)(StationsListPage));
