import { SET_LANG } from './i18nActions';

export default function i18nReducer(state = { lang: '', loaded: false }, action) {
  switch (action.type) {
    case SET_LANG:
      return Object.assign({}, state, { lang: action.lang, loaded: true });
    default:
      return state;
  }
}
