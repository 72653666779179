import { SET_CURRENTSTATION, SET_CURRENTSTATION_ERROR } from './stationActions';

const initialState = {
  loaded: false,
  currentStation: null,
  error: null
};

export default function stationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENTSTATION:
      return Object.assign({}, state, { loaded: true, currentStation: action.currentStation, error: null });
    case SET_CURRENTSTATION_ERROR:
      return Object.assign({}, state, { loaded: true, error: action.error, currentStation: null });
    default:
      return state;
  }
}
