import Container from "react-bootstrap/Container";
import I18n from "../../models/i18n";
import TitleLine from "./TitleLine";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

export default function() {
  return (
    <Container>
      <h6>{I18n.translate("modal", "tabellaPM_desc1")}</h6>
      <h6>{I18n.translate("modal", "tabellaPM_desc2")}</h6>
      <TitleLine color="green" />
      <Row className="show-grid">
        <Col xs={5}>
          <p style={{ color: "#FF0000" }}>
            {I18n.translate("modal", "red_line")}
          </p>
        </Col>
        <Col xs={7}>
          <p>{I18n.translate("modal", "40mgm3")}</p>
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={5}>
          <p style={{ color: "#FF9C33" }}>
            {I18n.translate("modal", "orange_line")}
          </p>
        </Col>
        <Col xs={7}>
          <p>{I18n.translate("modal", "20mgm3")}</p>
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={5}>
          <p style={{ color: "#FFC300" }}>
            {I18n.translate("modal", "yellow_line")}
          </p>
        </Col>
        <Col xs={7}>
          <p>{I18n.translate("modal", "25mgm3")}</p>
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={5}>
          <p style={{ color: "#1DE326" }}>
            {I18n.translate("modal", "green_line")}
          </p>
        </Col>
        <Col xs={7}>
          <p>{I18n.translate("modal", "10mgm3")}</p>
        </Col>
      </Row>
    </Container>
  );
}
