import I18n from './index';
import { setStorageKey } from '../../lib/utils/storageUtils';

export const SET_LANG = 'SET_LANG';

export function doGetTranslations(lang) {
  return dispatch => {
    if (I18n.lang === lang) {
      return;
    }
    return I18n.loadStrings(lang)
      .then(() => {
        setStorageKey('lang', lang);
        dispatch(setLang(lang));
      })
      .catch(error => {});
  };
}
function setLang(lang) {
  return {
    type: SET_LANG,
    lang
  };
}
