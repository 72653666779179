import React from 'react';
import {
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  DiscreteColorLegend,
  ChartLabel
} from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import TitleLine from '../CommonComponents/TitleLine';

class Pm25Chart extends BaseChart {
  static propTypes = {
    dataPm2_5: PropTypes.array.isRequired,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    ticksX: PropTypes.number,
    sensors: PropTypes.object
  };
  state = {
    valuePm2_5: false
  };

  render() {
    const { width, dataPm2_5, sensors } = this.props;
    const yValuesArray = dataPm2_5.map(item => {
      return item.y;
    });
    const yMaxValue = Math.max.apply(Math, yValuesArray);
    const xStart = dataPm2_5[0].x;
    const xEnd = dataPm2_5[dataPm2_5.length - 1].x;
    const cutOffPmVeryGood = [
      { x: xStart, y: 0.2 },
      { x: xEnd, y: 0.2 }
    ];
    const cutOffPmGood = [
      { x: xStart, y: 10 },
      { x: xEnd, y: 10 }
    ];
    const cutOffPmFair = [
      { x: xStart, y: 20 },
      { x: xEnd, y: 20 }
    ];
    const cutOffPmPoor = [
      { x: xStart, y: 25 },
      { x: xEnd, y: 25 }
    ];
    const cutOffPmVeryPoor = [
      { x: xStart, y: 50 },
      { x: xEnd, y: 50 }
    ];
    const { h, w } = this.getHeight(width);

    let subtitle = '';
    if (sensors.tolerance) {
      const values = [{ id: 'PM2_5', desc: 'PM2.5' }];
      subtitle =
        '(' +
        values
          .map(k => {
            return k.desc + ': ' + sensors.tolerance[k.id];
          })
          .join(', ') +
        ')';
    }

    return (
      <ChartWrapper
        title={I18n.translate('data', 'tabellaPM_title')}
        subtitle={subtitle}
        modalContent={
          <Container>
            <h6>{I18n.translate('modal', 'tabellaPM_desc1')}</h6>
            <h6>{I18n.translate('modal', 'tabellaPM_desc2')}</h6>
            <TitleLine color="green" />
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#FF0000' }}>Very Poor</p>
              </Col>
              <Col xs={6}>
                <p>&gt; 50</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#FF9C33' }}>Poor</p>
              </Col>
              <Col xs={6}>
                <p>&gt; 25 and &lt; 50</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#FFC300' }}>Fair</p>
              </Col>
              <Col xs={6}>
                <p>&gt; 20 and &lt; 25</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#1DE326' }}>Good</p>
              </Col>
              <Col xs={6}>
                <p>&gt; 10 and &lt; 20</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={6}>
                <p style={{ color: '#1DE3E0' }}>Very Good</p>
              </Col>
              <Col xs={6}>
                <p>&lt; 10</p>
              </Col>
            </Row>
          </Container>
        }
      >
        <div className="legend-wrapper">
          <DiscreteColorLegend
            items={[
              {
                title: 'PM2.5',
                color: '#27AADB'
              }
            ]}
          />{' '}
        </div>
        <XYPlot
          xType="time"
          width={w}
          height={h}
          onMouseLeave={() => this.setState({ valuePm1: false, valuePm2_5: false, valuePm10: false })}
          onTouchEnd={() => this.setState({ valuePm1: false, valuePm2_5: false, valuePm10: false })}
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
          xDomain={[xStart, xEnd]}
          yDomain={[0, yMaxValue + 1]}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={this.props.ticksX} />
          <YAxis />

          <ChartLabel
            text="PM2.5"
            className="alt-y-label"
            includeMargin={false}
            xPercent={width >= 900 ? -0.04 : width >= 500 ? -0.075 : -0.12}
            yPercent={0.5}
            style={{
              transform: 'rotate(-90)',
              textAnchor: 'middle'
            }}
            curve={'curveMonotoneX'}
          />
          <LineSeries
            style={{ zIndex: '998' }}
            data={dataPm2_5}
            onNearestX={valuePm2_5 => this.setState({ valuePm2_5 })}
            color="#27AADB"
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmVeryGood}
            stroke="#1DE3E0"
            strokeStyle="dashed"
            strokeWidth={3}
            title="Very Good"
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmGood}
            stroke="#1DE326"
            strokeStyle="dashed"
            strokeWidth={3}
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmFair}
            stroke="#FFEC33"
            strokeStyle="dashed"
            strokeWidth={3}
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmPoor}
            stroke="#FF9C33"
            strokeStyle="dashed"
            strokeWidth={3}
          />
          <LineSeries
            style={{ zIndex: '800', opacity: '0.3' }}
            data={cutOffPmVeryPoor}
            stroke="#FF0000"
            strokeStyle="dashed"
            strokeWidth={3}
          />

          {this.state.valuePm2_5 && (
            <Hint value={this.state.valuePm2_5}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                {I18n.translate('data', 'date_time')}:{' '}
                <strong>{this.props.getDateTime(this.state.valuePm2_5.x)}</strong>
                <br />
                PM2.5: <strong>{this.state.valuePm2_5 ? this.state.valuePm2_5.y : '-'}</strong>
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }
}

export default Pm25Chart;
