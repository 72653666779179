import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import I18n, { doGetTranslations } from '../../models/i18n';

class Navbar extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired
  };

  state = {
    open: false,
    prevScrollpos: window.pageYOffset,
    visible: true
  };

  render() {
    const y = window.scrollY;
    return (
      <nav
        className={
          'navbar navbar-expand-md navbar-dark bg-green fixed-top' +
          (!this.state.visible ? ' navbar--hidden' : y !== 0 ? ' navbar--shown' : '')
        }
      >
        <NavLink strict to="/" exact className="navbar-brand">
          MilanoSmartPark
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.toggleNavBar}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={'collapse navbar-collapse' + (this.state.open ? ' show' : '')} id="navbarCollapse">
          <div className="navbar-nav ml-5 mr-auto">
            <NavLink strict to="/stations" className="nav-link nav-item">
              {I18n.translate('navbar', 'stations')}
            </NavLink>
            <NavLink strict to="/stats" className="nav-link nav-item">
              {I18n.translate('navbar', 'stats')}
            </NavLink>
          </div>
          <div className="ml-auto">
            <button
              className={'navbar-button' + (this.props.lang.lang !== 'it' ? ' spento' : '')}
              onClick={e => {
                this.props.dispatch(doGetTranslations('it'));
              }}
            >
              IT
            </button>
            <button
              className={'navbar-button' + (this.props.lang.lang !== 'en' ? ' spento' : '')}
              onClick={e => {
                this.props.dispatch(doGetTranslations('en'));
              }}
            >
              EN
            </button>
          </div>
        </div>
      </nav>
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (this.scrollTo) {
      clearTimeout(this.scrollTo);
    }
    const { prevScrollpos } = this.state;
    const currentScrollpos = window.pageYOffset;
    const visible = prevScrollpos >= currentScrollpos;
    this.scrollTo = setTimeout(() => {
      this.setState({
        prevScrollpos: currentScrollpos,
        visible,
        open: false
      });
    }, 150);
  };

  toggleNavBar = e => {
    this.setState({ open: !this.state.open });
  };
}

export default Navbar;
