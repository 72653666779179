export const getMSPColor = function(msp) {
  switch (msp) {
    case 1:
      return 'green';
    case 2:
      return '#e7e774';
    case 3:
      return 'red';
    case 4:
      return 'black';
    default:
      return 'white';
  }
};
