export const getStorageKey = function(name, defaultValue) {
  try {
    return localStorage.getItem(name) || defaultValue;
  } catch (e) {
    console.error('Unable to get key %s from localStorage', name, e.message);
  }
  return defaultValue;
};

export const setStorageKey = function(name, value) {
  try {
    localStorage.setItem(name, value);
    return true;
  } catch (e) {
    console.error('Unable to store key %s in localStorage', name, e.message);
  }
  return false;
};
