export const SET_DATA = 'SET_DATA';
export const SET_DATA_ERROR = 'SET_DATA_ERROR';
export const RETRIEVE_DATA = 'RETRIEVE_DATA';

export function setData(data) {
  return {
    type: SET_DATA,
    data
  };
}

export function retrieveData() {
  return {
    type: RETRIEVE_DATA
  };
}
