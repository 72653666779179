import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Loading extends PureComponent {
  static propTypes = {};

  render() {
    return (
      <div className="overlay">
        <div className="overlay-content overlay-active">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
