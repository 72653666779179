import i18nReducer from './i18nReducer';
import { getData, postData } from '../../lib/utils/httputils';

class I18n {
  static translations = {};
  static lang;
  static async loadStrings(lang) {
    I18n.lang = lang;
    let path = '/i18n/' + lang + '.json';
    I18n.translations = await getData(path);
  }
  static translate(context, string) {
    if (I18n.translations[context] && I18n.translations[context][string]) {
      return I18n.translations[context][string];
    }
    // Notifica mancanza stringa
    // I18n.notifyMissingTranslation(context, string).finally();
    return '#' + context + '#' + string;
  }
  static async notifyMissingTranslation(context, string) {
    const path = '/api/v1/translations.json';
    const data = {
      context: context || '',
      string: string || ''
    };
    if (!path) {
      return;
    }
    postData(path, data, 'application/json; charset=UTF-8'); // ma come?
  }
}

export default I18n;

export { i18nReducer };
export * from './i18nActions';
